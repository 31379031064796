import { state } from ":mods";
import { getJobs } from "../..";
import { createEffect } from "solid-js";
import { A } from "@solidjs/router";

export default function DescriptionSection() {
  const $jobsList = state.create([]);
  const mainTitle =
    "Welcome to your gateway to a directory of live opportunities museums and cultural institutions across the Kingdom of Saudi Arabia. Whether you're a seasoned professional seeking a new adventure or a alumni from our programme looking to kickstart your career, this is where your journey begins.";
  const mainDesc =
    "Listing jobs on this platform is free, Email us on jobs@email.com to let us know about any opportunities that your organisation may have. We also provide a premium recruitment if you are looking to fill a more senior or specialist role.";

  const $db_jobs = state.createAsync(getJobs);
  createEffect(() => {
    $jobsList.set($db_jobs.value?.data);
  });
  return (
    <section class="flex flex-col bg#paper items-center">
      <div class="flex flex-col gap-40px justify-center items-center my-80px">
        <article class="w-845px mx-auto flex flex-col gap-20px">
          <p class="text-25px font-bold leading-28px">{mainTitle}</p>
          <p class="text-16px font-normal leading-24px">{mainDesc}</p>
        </article>
        <ul id="jobs-section" class="flex flex-col gap-40px w-710px">
          {$jobsList.value?.map(({ id, title, department_name, region, country }) => {
            return (
              <li class="flex flex-col gap-10px list-none ">
                <p class="text-16px font-bold leading-24px">{department_name}</p>
                <p class="text-16px font-normal leading-24px underline">{`${title} | Check more details >`}</p>
                {/* <A class="text#p cursor-pointer underline-offset-2" href={`${id}`}>
                </A> */}
                <p class="text-16px font-bold leading-24px text#n-200">
                  {`${region ?? "UNDEFINED REGION"} / ${country ?? "UNDEFINED COUNTRY"}`}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
      <hr class="max-w-screen w-screen h-2px bg#p" />
    </section>
  );
}
